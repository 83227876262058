import * as styles from './AttributeSelection.module.scss'

import React, { useCallback, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { compact } from 'lodash'

import * as controls from 'components/controls'

import { moveItem } from 'src/utils/collections'

import { Attribute, OutputAttribute, OutputMode } from '../../data/types'
import { groupedCardAttributes } from '../../data/cardAttributes'

import { Presets } from './Presets'
import { AttributeRow } from './AttributeRow'
import { attributeForSlug, slugForAttribute } from './attributeSlug'

interface Props {
  outputMode: OutputMode
  customAttributes?: Attribute[]
  outputAttributes: OutputAttribute[]
  setOutputAttributes(value: OutputAttribute[]): void
  includeHeadings: boolean
  setIncludeHeadings(value: boolean): void
}

export const AttributeSelection: React.FC<Props> = (props) => {
  const {
    outputMode,
    customAttributes,
    outputAttributes,
    setOutputAttributes,
    includeHeadings,
    setIncludeHeadings,
  } = props

  const attributeSelectOptions = useMemo(() => {
    let inputAttributes

    if (customAttributes != null && customAttributes.length > 0) {
      inputAttributes = [
        { name: 'Input Attributes', label: 'Input Attributes', disabled: true },
        ...customAttributes.map((attribute) => ({
          name: slugForAttribute('input', attribute.name),
          label: attribute.label,
        })),
      ]
    }

    return [
      ...(inputAttributes ?? []),
      ...groupedCardAttributes.flatMap((group) => {
        return compact([
          group.label != null
            ? { name: group.label, label: group.label, disabled: true }
            : null,
          ...group.attributes.map((attribute) => ({
            name: slugForAttribute('card', attribute.name),
            label: attribute.label,
          })),
        ])
      }),
    ]
  }, [customAttributes])

  const addAttribute = useCallback(
    (name: string) => {
      const [source, key] = attributeForSlug(name)

      const newAttribute = {
        key,
        fillValue: key === '' ? '' : undefined,
        id: uuid(),
        source,
      }

      setOutputAttributes([...outputAttributes, newAttribute])
    },
    [outputAttributes, setOutputAttributes],
  )

  const editAttribute = useCallback(
    (index: number, update: Partial<OutputAttribute>) => {
      const nextState = [...outputAttributes]
      nextState[index] = { ...outputAttributes[index], ...update } as any

      setOutputAttributes(nextState)
    },
    [outputAttributes, setOutputAttributes],
  )

  const removeAttribute = useCallback(
    (index: number) => {
      setOutputAttributes(outputAttributes.filter((_, i) => i !== index))
    },
    [outputAttributes, setOutputAttributes],
  )

  const moveAttribute = useCallback(
    (source: number, destination: number) => {
      setOutputAttributes(moveItem(outputAttributes, source, destination))
    },
    [outputAttributes, setOutputAttributes],
  )

  return (
    <div className={styles.container}>
      {outputMode === 'csv' && (
        <div className={styles.csvOptions}>
          <controls.GroupLabel>CSV Options</controls.GroupLabel>

          <controls.CheckboxField
            label="Include Header Row"
            checked={includeHeadings}
            onChange={(event) =>
              setIncludeHeadings(event.currentTarget.checked)
            }
          />
        </div>
      )}

      <div className={styles.presets}>
        <Presets
          outputAttributes={outputAttributes}
          setOutputAttributes={setOutputAttributes}
        />
      </div>

      <div>
        {outputAttributes.length > 0 ? (
          <>
            <div className={styles.headings}>
              <controls.GroupLabel>Card Attribute</controls.GroupLabel>
              <controls.GroupLabel>Label</controls.GroupLabel>
            </div>

            <div className={styles.rows}>
              {outputAttributes.map((outputAttribute, index) => (
                <AttributeRow
                  key={outputAttribute.id}
                  index={index}
                  outputAttribute={outputAttribute}
                  attributeSelectOptions={attributeSelectOptions}
                  editAttribute={editAttribute}
                  moveAttribute={moveAttribute}
                  removeAttribute={removeAttribute}
                />
              ))}
            </div>
          </>
        ) : (
          <div className={styles.empty}>
            Choose a preset or add card attributes to display
          </div>
        )}
      </div>

      <div className={styles.addColumn}>
        <controls.Select
          options={[
            { name: '', label: '+ Add Card Attribute', disabled: true },
            ...attributeSelectOptions,
          ]}
          value=""
          onChange={(event) => {
            event.currentTarget
            addAttribute(event.currentTarget.value)
          }}
        />
        <controls.SmallButton onClick={() => addAttribute('')} secondary>
          + Add Custom Column
        </controls.SmallButton>
      </div>
    </div>
  )
}
