import * as styles from './ListInputConfiguration.module.scss'

import React, { useCallback } from 'react'

import * as controls from 'components/controls'

import { Attribute } from '../data/types'
import { IDTransformer, scryfallIdentifiers } from '../data/inferIDTransformer'

interface Props {
  listInputMode: 'text' | 'csv'
  listInputAttributes: Attribute[]
  idTransformer: Partial<IDTransformer>
  setIDTransformer(idTransformer: Partial<IDTransformer>): void
  overrideIDTransformer: boolean
  setOverrideIDTransformer(value: boolean): void
}

export const ListInputConfiguration: React.FC<Props> = (props) => {
  const {
    listInputAttributes,
    idTransformer,
    setIDTransformer,
    overrideIDTransformer,
    setOverrideIDTransformer,
  } = props

  const setTransformerType = useCallback(
    (name: string) => {
      const identifier = scryfallIdentifiers.find((id) => id.name === name)

      if (identifier == null) {
        throw new Error(`No Scryfall identifier with name ${name}`)
      }

      setIDTransformer({
        ...identifier,
        sourceAttributes: identifier.attributes.reduce((result, attribute) => {
          result[attribute.name] = listInputAttributes[0]
          return result
        }, {} as Record<string, Attribute>),
      })
    },
    [listInputAttributes, setIDTransformer],
  )

  const setAttribute = useCallback(
    (name: string, source: string) => {
      const sourceAttributes = { ...idTransformer.sourceAttributes }
      sourceAttributes[name] =
        listInputAttributes.find((a) => a.name === source) ?? null

      setIDTransformer({
        ...idTransformer,
        sourceAttributes,
      })
    },
    [idTransformer, listInputAttributes, setIDTransformer],
  )

  return (
    <div className={styles.container}>
      {overrideIDTransformer ? (
        <div className={styles.fields}>
          <div className={styles.field}>
            <div>Lookup cards by:</div>
            <controls.Select
              value={idTransformer?.name ?? ''}
              onChange={(event) =>
                setTransformerType(event.currentTarget.value)
              }
              options={scryfallIdentifiers}
            />
          </div>

          {/* If there's only a single column in the input, it'll be used so don't show the option */}
          {idTransformer.attributes != null &&
            listInputAttributes.length > 1 &&
            idTransformer.attributes.map((attribute) => (
              <div key={attribute.name} className={styles.field}>
                <div>
                  {idTransformer.attributes!.length > 1
                    ? `${attribute.label} column:`
                    : 'Column Name'}
                </div>
                <controls.Select
                  value={
                    idTransformer.sourceAttributes?.[attribute.name]?.name ?? ''
                  }
                  onChange={(event) =>
                    setAttribute(attribute.name, event.currentTarget.value)
                  }
                  options={listInputAttributes}
                />
              </div>
            ))}
        </div>
      ) : (
        <div className={styles.fields}>
          <div>
            Lookup method: <strong>{idTransformer?.label}</strong>
          </div>

          {idTransformer.attributes != null &&
            listInputAttributes.length > 1 &&
            idTransformer.attributes.map((attribute) => (
              <div key={attribute.name}>
                {idTransformer.attributes!.length > 1
                  ? `${attribute.label} column: `
                  : 'Column Name: '}
                &ldquo;
                <strong>
                  {idTransformer.sourceAttributes?.[attribute.name]?.label}
                </strong>
                &rdquo;
              </div>
            ))}
        </div>
      )}

      <div>
        <controls.CheckboxField
          checked={overrideIDTransformer}
          onChange={(event) =>
            setOverrideIDTransformer(event.currentTarget.checked)
          }
          label="Override Columns"
        />
      </div>
    </div>
  )
}
