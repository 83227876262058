import { compact, kebabCase } from 'lodash'

import * as scryfall from 'utils/scryfall'

import { RowWithData } from '../data/types'
import { FileNameType, ImageSetDownloadOptions } from './types'

function nameForRow(
  name: string,
  card: scryfall.Card,
  fileNameType: FileNameType,
) {
  switch (fileNameType) {
    case 'set-number-slug':
      return `${card.set.toLowerCase()}-${card.collector_number.toLowerCase()}-${kebabCase(
        card.name,
      )}`
    case 'scryfall-id':
      return card.id
    default:
      return kebabCase(name)
  }
}

/**
 * For a set of cards and an image type, generate a set of descriptions of files
 * to download.
 */
export function downloadsForCards(
  rows: RowWithData[],
  options: ImageSetDownloadOptions,
): {
  downloads: {
    fileName: string
    url: string
  }[]
  missingRows: RowWithData[]
} {
  const { imageType, fileNameType } = options

  const fileNames: Record<string, number> = {}
  const missingRows: RowWithData[] = []

  const downloads = rows.flatMap((row) => {
    if (row.card == null) {
      missingRows.push(row)
      return []
    }

    const faces =
      row.card.card_faces != null && row.card.card_faces[0].image_uris != null
        ? row.card.card_faces
        : [row.card]

    return compact(
      faces.map((face) => {
        const name = face.name
        const imageURL = face.image_uris[imageType]

        if (name == null || imageURL == null) {
          missingRows.push(row)
          return null
        }

        const extension = imageURL.split(/[#?]/)[0]?.split('.')?.pop()?.trim()

        // Append a number to duplicate file names
        let fileName = nameForRow(face.name, row.card!, fileNameType)
        if (fileNames[fileName] != null) {
          fileNames[fileName] = fileNames[fileName] + 1
          fileName = `${fileName}-${fileNames[fileName]}`
        } else {
          fileNames[fileName] = 1
        }

        return {
          fileName: `${fileName}.${extension}`,
          url: imageURL,
        }
      }),
    )
  })

  return {
    downloads,
    missingRows,
  }
}
