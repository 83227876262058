import * as styles from './AttributeRow.module.scss'

import React, { useMemo, useState } from 'react'
import classNames from 'classnames'

import * as controls from 'components/controls'

import { OutputAttribute } from '../../data/types'
import { HorizontalBars } from '../../icons/HorizontalBars'
import { Remove } from '../../icons/Remove'
import { attributeForSlug, slugForAttribute } from './attributeSlug'

interface Props {
  index: number
  outputAttribute: OutputAttribute
  attributeSelectOptions: {
    name: string
    label: string
  }[]
  editAttribute(index: number, update: Partial<OutputAttribute>): void
  moveAttribute(source: number, destination: number): void
  removeAttribute(index: number): void
}

export const AttributeRow: React.FC<Props> = (props) => {
  const {
    index,
    outputAttribute,
    attributeSelectOptions,
    editAttribute,
    moveAttribute,
    removeAttribute,
  } = props

  const [draggable, setDraggable] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [draggedOver, setDraggedOver] = useState(false)

  const isCustom = useMemo(() => {
    if (outputAttribute.fillValue != null || outputAttribute.key.length === 0) {
      return true
    }

    const slugs =
      outputAttribute.source == null
        ? [
            slugForAttribute('input', outputAttribute.key),
            slugForAttribute('card', outputAttribute.key),
          ]
        : slugForAttribute(outputAttribute.source, outputAttribute.key)

    return !attributeSelectOptions.some((a) => slugs.includes(a.name))
  }, [attributeSelectOptions, outputAttribute])

  const selectValue = useMemo(() => {
    if (outputAttribute.source != null) {
      return slugForAttribute(outputAttribute.source, outputAttribute.key)
    }
    return attributeSelectOptions.find(
      (a) => attributeForSlug(a.name)[1] === outputAttribute.key,
    )?.name
  }, [attributeSelectOptions, outputAttribute])

  return (
    <div
      className={classNames(styles.container, {
        [styles.dragging]: dragging,
        [styles.draggedOver]: draggedOver,
      })}
      draggable={draggable}
      onDragStart={(event) => {
        event.dataTransfer.setData('text/plain', index.toString())
        event.dataTransfer.dropEffect = 'move'
        setDragging(true)
      }}
      onDragOver={(event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
        setDraggedOver(true)
      }}
      onDragLeave={(event) => {
        event.preventDefault()
        setDraggedOver(false)
      }}
      onDrop={(event) => {
        event.preventDefault()
        setDraggedOver(false)
        const sourceIndex = parseInt(event.dataTransfer.getData('text/plain'))
        moveAttribute(sourceIndex, index)
      }}
      onDragEnd={() => {
        setDragging(false)
        setDraggedOver(false)
      }}
    >
      {isCustom ? (
        <controls.TextInput
          value={outputAttribute.key}
          onChange={(event) => {
            editAttribute(index, {
              key: event.currentTarget.value,
            })
          }}
          placeholder="Attribute name"
          accent
        />
      ) : (
        <controls.Select
          options={attributeSelectOptions}
          value={selectValue ?? ''}
          onChange={(event) => {
            const [source, key] = attributeForSlug(event.currentTarget.value)

            editAttribute(index, {
              source,
              key,
            })
          }}
        />
      )}

      <controls.TextInput
        value={outputAttribute.label ?? ''}
        placeholder={outputAttribute.key}
        onChange={(event) =>
          editAttribute(index, { label: event.currentTarget.value })
        }
      />

      {isCustom && (
        <div className={styles.fillOption}>
          <div className={styles.secondRowIndicator} />
          <controls.TextInput
            value={outputAttribute.fillValue ?? ''}
            placeholder="Fill Value"
            onChange={(event) =>
              editAttribute(index, { fillValue: event.currentTarget.value })
            }
          />
        </div>
      )}

      <div className={styles.controls}>
        <div
          className={styles.dragHandle}
          title="Drag to reorder"
          onMouseDown={() => setDraggable(true)}
          onMouseUp={() => setDraggable(false)}
        >
          <HorizontalBars />
        </div>

        <button
          className={styles.removeButton}
          onClick={() => removeAttribute(index)}
        >
          <Remove />
        </button>
      </div>
    </div>
  )
}
