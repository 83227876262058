import * as styles from './SearchConfiguration.module.scss'

import React from 'react'

interface Props {
  globalQueryExtension: string
  setGlobalQueryExtension(value: string): void
}

export const SearchConfiguration: React.FC<Props> = (props) => {
  const { globalQueryExtension, setGlobalQueryExtension } = props

  return (
    <div>
      <label className={styles.inputField}>
        Global query options
        <input
          className={styles.input}
          type="text"
          value={globalQueryExtension}
          onChange={(event) =>
            setGlobalQueryExtension(event.currentTarget.value)
          }
        />
      </label>
      <div className={styles.hint}>
        Additional query params to always apply, e.g. add{' '}
        <strong>&ldquo;game:paper -t:basic&rdquo;</strong> to exclude arena only
        cards and basic lands.
      </div>
    </div>
  )
}
