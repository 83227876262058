import { v4 as uuid } from 'uuid'

import { OutputAttribute } from './types'

interface Preset {
  name: string
  attributes: OutputAttribute[]
}

const cardNames: Preset = {
  name: 'Names',
  attributes: [
    {
      key: 'name',
    },
  ].map((attribute) => ({ ...attribute, id: uuid() })),
}

const basic: Preset = {
  name: 'Basic',
  attributes: [
    {
      key: 'name',
    },
    {
      key: 'type_line',
    },
    {
      key: 'mana_cost',
    },
    {
      key: 'cmc',
    },
    {
      key: 'set',
    },
    {
      key: 'rarity',
    },
  ].map((attribute) => ({ ...attribute, id: uuid() })),
}

const moxfield: Preset = {
  name: 'Moxfield',
  attributes: [
    {
      key: 'count',
      label: 'Count',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'set',
      label: 'Edition',
    },
    {
      key: 'condition',
      label: 'Condition',
      source: 'input' as const,
    },
    {
      key: 'lang',
      label: 'Language',
    },
    {
      key: 'foil',
      label: 'Foil',
      source: 'input' as const,
    },
    {
      key: 'collector_number',
      label: 'Collector Number',
    },
  ].map((attribute) => ({ ...attribute, id: uuid() })),
}

export const moxfieldExport: Preset = {
  name: 'Moxfield Export',
  attributes: [
    {
      key: 'count',
      label: 'Count',
      source: 'input' as const,
    },
    {
      key: 'tradelist_count',
      label: 'Tradelist Count',
      source: 'input' as const,
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'set',
      label: 'Edition',
    },
    {
      key: 'condition',
      label: 'Condition',
      source: 'input' as const,
    },
    {
      key: 'lang',
      label: 'Language',
    },
    {
      key: 'foil',
      label: 'Foil',
      source: 'input' as const,
    },
    {
      key: 'tags',
      label: 'Tags',
      source: 'input' as const,
    },
    {
      key: 'last_modified',
      label: 'Last Modified',
      source: 'input' as const,
    },
    {
      key: 'collector_number',
      label: 'Collector Number',
    },
    {
      key: 'alter',
      label: 'Alter',
      source: 'input' as const,
    },
    {
      key: 'proxy',
      label: 'Proxy',
      source: 'input' as const,
    },
    {
      key: 'purchase_price',
      label: 'Purchase Price',
      source: 'input' as const,
    },
  ].map((attribute) => ({ ...attribute, id: uuid() })),
}

const cubeCobra: Preset = {
  name: 'Cube Cobra',
  attributes: [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'cmc',
      label: 'CMC',
    },
    {
      key: 'type_line',
      label: 'Type',
    },
    {
      key: 'colors',
      label: 'Color',
    },
    {
      key: 'set',
      label: 'Set',
    },
    {
      key: 'collector_number',
      label: 'Collector Number',
    },
    {
      key: 'rarity',
      label: 'Rarity',
    },
    {
      key: 'color_identity',
      label: 'Color Category',
    },
    {
      key: 'status',
      label: 'Status',
      source: 'input' as const,
    },
    {
      key: 'finish',
      label: 'Finish',
      source: 'input' as const,
    },
    {
      key: 'maybeboard',
      label: 'Maybeboard',
      source: 'input' as const,
    },
    {
      key: 'image_url',
      label: 'Image URL',
      source: 'input' as const,
    },
    {
      key: 'image_back_url',
      label: 'Image Back URL',
      source: 'input' as const,
    },
    {
      key: 'tags',
      label: 'Tags',
      source: 'input' as const,
    },
    {
      key: 'notes',
      label: 'Notes',
      source: 'input' as const,
    },
    {
      key: 'mtgo_id',
      label: 'MTGO ID',
    },
  ].map((attribute) => ({ ...attribute, id: uuid() })),
}

const archidektExport: Preset = {
  name: 'Archidekt',
  attributes: [
    {
      key: 'count',
      label: 'Quantity',
      source: 'input' as const,
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'finish',
      label: 'Finish',
      source: 'input' as const,
    },
    {
      key: 'condition',
      label: 'Condition',
      source: 'input' as const,
    },
    {
      key: 'lang',
      label: 'Language',
    },
    {
      key: 'date',
      label: 'Date Added',
      source: 'input' as const,
    },
    {
      key: 'set_name',
      label: 'Edition Name',
    },
    {
      key: 'set',
      label: 'Edition Code',
    },
    {
      key: 'multiverse_ids',
      label: 'Multiverse Id',
    },
    {
      key: 'id',
      label: 'Scryfall ID',
    },
    {
      key: 'mtgo_id',
      label: 'MTGO ID',
    },
    {
      key: 'collector_number',
      label: 'Collector Number',
    },
  ].map((attribute) => ({ ...attribute, id: uuid() })),
}

const cardSphere: Preset = {
  name: 'Card Sphere',
  attributes: [
    {
      key: 'count',
      label: 'Count',
      source: 'input' as const,
    },
    {
      key: 'tradelist_count',
      label: 'Tradelist Count',
      source: 'input' as const,
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'set_name',
      label: 'Edition',
    },
    {
      key: 'condition',
      label: 'Condition',
      source: 'input' as const,
    },
    {
      key: 'lang',
      label: 'Language',
    },
    {
      key: 'foil',
      label: 'Foil',
      source: 'input' as const,
    },
    {
      key: 'tags',
      label: 'Tags',
    },
  ].map((attribute) => ({ ...attribute, id: uuid() })),
}

export const standardOutputConfiguration = basic.attributes

export const outputConfigurationPresets = [
  cardNames,
  basic,
  moxfield,
  cubeCobra,
  archidektExport,
  cardSphere,
]
