import * as styles from './Footer.module.scss'

import React from 'react'
import { Link } from 'gatsby'

export const Footer: React.FC = () => {
  return (
    <footer className={styles.container}>
      &copy; 2023 <Link to="/">Lucky Paper</Link>. Card images and data courtesy
      of <a href="https://scryfall.com/">Scryfall</a>.
    </footer>
  )
}
