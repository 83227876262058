import * as styles from './SortOptions.module.scss'

import React from 'react'

import * as controls from 'components/controls'

import { SortMethod } from '../data/types'
import { groupedCardAttributes } from '../data/cardAttributes'
import {
  attributeForSlug,
  slugForAttribute,
} from './attribute-selection/attributeSlug'

interface Props {
  sortMethod: SortMethod | null
  setSortMethod(value: SortMethod | null): void
}

const directionAttributes = [
  { label: 'Ascending', name: 'asc' },
  { label: 'Descending', name: 'desc' },
]

const attributeOptions = [
  { name: '', label: 'Default' },
  ...groupedCardAttributes.flatMap((group) => {
    const sortableAttributes = group.attributes.filter(
      (attribute) => attribute.sortable,
    )
    if (sortableAttributes.length === 0) {
      return []
    }
    return [
      { name: '-', label: group.label, disabled: true },
      ...sortableAttributes.map((attribute) => ({
        name: slugForAttribute('card', attribute.name),
        label: attribute.label,
      })),
    ]
  }),
]

export const SortOptions: React.FC<Props> = (props) => {
  const { sortMethod, setSortMethod } = props

  return (
    <div className={styles.container}>
      <div>Sort Results By:</div>

      <controls.ControlGroup>
        <controls.Select
          value={
            sortMethod?.key ? slugForAttribute('card', sortMethod?.key) : ''
          }
          options={attributeOptions}
          onChange={(event) => {
            if (event.currentTarget.value === '') {
              setSortMethod(null)
            } else {
              const [source, key] = attributeForSlug(event.currentTarget.value)
              setSortMethod({
                key,
                source,
                ascending: sortMethod?.ascending ?? true,
              })
            }
          }}
        />
        {sortMethod != null && (
          <controls.Select
            value={sortMethod?.ascending ? 'asc' : 'desc'}
            options={directionAttributes}
            onChange={(event) => {
              setSortMethod({
                key: sortMethod.key,
                source: sortMethod.source,
                ascending: event.currentTarget.value === 'asc',
              })
            }}
          />
        )}
      </controls.ControlGroup>
    </div>
  )
}
