import * as styles from './TextConfiguration.module.scss'

import React, { useCallback, useState } from 'react'

import * as controls from 'components/controls'

import { formatExamples, formattableAttributes } from '../data/formatText'

interface Props {
  rowTemplate: string
  setRowTemplate(value: string): void
}

export const TextConfiguration: React.FC<Props> = (props) => {
  const { rowTemplate, setRowTemplate } = props

  const exampleValue = formatExamples.find(
    (v) => v.template === rowTemplate,
  )?.template

  const [showAttributes, setShowAttributes] = useState(false)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        setRowTemplate(event.currentTarget.value as any)
      }
    },
    [setRowTemplate],
  )

  const appendAttribute = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setRowTemplate(`${rowTemplate}{${event.currentTarget.value}}`)
    },
    [rowTemplate, setRowTemplate],
  )

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.label}>Export Card Format</div>

        <div className={styles.exampleOptions}>
          {formatExamples.map((option) => (
            <label key={option.template} className={styles.exampleOption}>
              <div className={styles.radioButton}>
                <controls.RadioButton
                  value={option.template}
                  onChange={handleChange}
                  checked={exampleValue === option.template}
                />
              </div>
              <div className={styles.exampleLabel}>
                <div>{option.label}</div>
                <div className={styles.exampleSample}>{option.sample}</div>
              </div>
            </label>
          ))}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.heading}>
          <div className={styles.label}>Customize Format</div>
          {showAttributes ? (
            <controls.TextButton onClick={() => setShowAttributes(false)}>
              Hide Attributes
            </controls.TextButton>
          ) : (
            <controls.TextButton onClick={() => setShowAttributes(true)}>
              Show Attributes
            </controls.TextButton>
          )}
        </div>

        <div>
          <input
            type="text"
            value={rowTemplate}
            onChange={(event) => setRowTemplate(event.currentTarget.value)}
            className={styles.textInput}
          />
          <div className={styles.hint}>
            A template for each card. Attributes in curly braces{' '}
            <code className={styles.code}>{'{ }'}</code> will be replaced with
            the card&rsquo;s values.
          </div>
        </div>

        {showAttributes && (
          <div>
            <div className={styles.attributes}>
              {formattableAttributes.map((attribute) => (
                <button
                  key={attribute.name}
                  className={styles.attribute}
                  onClick={appendAttribute}
                  value={attribute.slug}
                >
                  {attribute.slug}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
