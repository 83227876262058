import * as scryfall from 'utils/scryfall'

export interface CardAttribute {
  name: keyof scryfall.Card
  label: string
  shortLabel?: string
  slug: string
  type?: 'string' | 'number' | 'string[]' | 'null'
  sortable?: boolean
}

export const groupedCardAttributes: {
  label: string
  attributes: CardAttribute[]
}[] = [
  {
    label: 'Card Attributes',
    attributes: [
      {
        name: 'name',
        label: 'Name',
        slug: 'name',
        type: 'string',
        sortable: true,
      },
      {
        name: 'mana_cost',
        label: 'Mana Cost',
        slug: 'mana-cost',
        type: 'string',
        sortable: true,
      },
      {
        name: 'cmc',
        label: 'Mana Value',
        shortLabel: 'MV',
        slug: 'mana-value',
        type: 'string',
        sortable: true,
      },
      {
        name: 'colors',
        label: 'Colors',
        slug: 'colors',
        type: 'string[]',
        sortable: true,
      },
      {
        name: 'color_identity',
        label: 'Color Identity',
        slug: 'color-identity',
        type: 'string[]',
        sortable: true,
      },
      {
        name: 'color_indicator',
        label: 'Color Indicator',
        slug: 'color-indicator',
        type: 'string[]',
        sortable: true,
      },
      {
        name: 'type_line',
        label: 'Type Line',
        slug: 'type-line',
        type: 'string',
        sortable: true,
      },
      {
        name: 'power',
        label: 'Power',
        shortLabel: 'Pow',
        slug: 'power',
        type: 'string',
        sortable: true,
      },
      {
        name: 'toughness',
        label: 'Toughness',
        shortLabel: 'Tou',
        slug: 'toughness',
        type: 'string',
        sortable: true,
      },
      {
        name: 'loyalty',
        label: 'Loyalty',
        slug: 'loyalty',
        type: 'string',
        sortable: true,
      },
      {
        name: 'oracle_text',
        label: 'Oracle Text',
        slug: 'oracle-text',
        type: 'string',
      },
      {
        name: 'keywords',
        label: 'Keywords',
        slug: 'keywords',
        type: 'string[]',
      },
      {
        name: 'rarity',
        label: 'Rarity',
        slug: 'rarity',
        type: 'string',
        sortable: true,
      },
      {
        name: 'flavor_text',
        label: 'Flavor Text',
        slug: 'flavor-text',
        type: 'string',
      },
    ],
  },
  {
    label: 'Set',
    attributes: [
      {
        name: 'set_id',
        label: 'Set ID',
        slug: 'set-id',
        type: 'string',
      },
      {
        name: 'set',
        label: 'Set Code',
        slug: 'set-code',
        type: 'string',
        sortable: true,
      },
      {
        name: 'set_name',
        label: 'Set Name',
        slug: 'set-name',
        type: 'string',
        sortable: true,
      },
      {
        name: 'set_type',
        label: 'Set Type',
        slug: 'set-type',
        type: 'string',
        sortable: true,
      },
      {
        name: 'set_uri',
        label: 'Set URL',
        slug: 'set-url',
        type: 'string',
      },
    ],
  },
  {
    label: 'Art & Images',
    attributes: [
      {
        name: 'artist',
        label: 'Artist',
        slug: 'artist',
        type: 'string',
      },
      {
        name: 'artist_ids',
        label: 'Artist IDs',
        slug: 'artist-ids',
        type: 'string[]',
      },
      {
        name: 'illustration_id',
        label: 'Illustration ID',
        slug: 'illustration-id',
        type: 'string',
      },
      {
        name: 'image_uris',
        label: 'Image URLs',
        slug: 'image-urls',
      },
    ],
  },
  {
    label: 'Meta',
    attributes: [
      {
        name: 'legalities',
        label: 'Legalities',
        slug: 'legalities',
      },
      {
        name: 'games',
        label: 'Games',
        slug: 'games',
      },
      {
        name: 'reserved',
        label: 'Reserved',
        slug: 'reserved',
      },
      {
        name: 'foil',
        label: 'Foil',
        slug: 'foil',
      },
      {
        name: 'nonfoil',
        label: 'Nonfoil',
        slug: 'nonfoil',
      },
      {
        name: 'finishes',
        label: 'Finishes',
        slug: 'finishes',
      },
      {
        name: 'oversized',
        label: 'Oversized',
        slug: 'oversized',
      },
      {
        name: 'promo',
        label: 'Promo',
        slug: 'promo',
      },
      {
        name: 'reprint',
        label: 'Reprint',
        slug: 'reprint',
        sortable: true,
      },
      {
        name: 'variation',
        label: 'Variation',
        slug: 'variation',
      },
      {
        name: 'collector_number',
        label: 'Collector Number',
        shortLabel: 'Number',
        slug: 'collector-number',
        type: 'string',
        sortable: true,
      },
      {
        name: 'booster',
        label: 'Booster',
        slug: 'booster',
        sortable: true,
      },
      {
        name: 'digital',
        label: 'Digital',
        slug: 'digital',
      },
    ],
  },
  {
    label: 'Frame and Layout',
    attributes: [
      {
        name: 'frame',
        label: 'Frame',
        slug: 'frame',
        sortable: true,
      },
      {
        name: 'layout',
        label: 'Layout',
        slug: 'layout',
        sortable: true,
      },
      {
        name: 'frame_effects',
        label: 'Frame Effects',
        slug: 'frame-effects',
      },
      {
        name: 'full_art',
        label: 'Full Art',
        slug: 'full-art',
      },
      {
        name: 'textless',
        label: 'Textless',
        slug: 'textless',
      },
      {
        name: 'border_color',
        label: 'Border Color',
        slug: 'border-color',
      },
      {
        name: 'security_stamp',
        label: 'Security Stamp',
        slug: 'security-stamp',
      },
    ],
  },
  {
    label: 'Platform IDs',
    attributes: [
      {
        name: 'id',
        label: 'Scryfall ID',
        slug: 'scryfall-id',
        type: 'string',
        sortable: true,
      },
      {
        name: 'oracle_id',
        label: 'Oracle ID',
        slug: 'oracle-id',
        type: 'string',
        sortable: true,
      },
      {
        name: 'multiverse_ids',
        label: 'Multiverse IDs',
        slug: 'multiverse-ids',
        type: 'string[]',
      },
      {
        name: 'mtgo_id',
        label: 'MTGO ID',
        slug: 'mtgo-id',
        type: 'string',
      },
      {
        name: 'mtgo_foil_id',
        label: 'MTGO Foil ID',
        slug: 'mtgo-foil-id',
        type: 'string',
      },
      {
        name: 'tcgplayer_id',
        label: 'TCGPlayer ID',
        slug: 'tcgplayer-id',
        type: 'string',
      },
      {
        name: 'cardmarket_id',
        label: 'Cardmarket ID',
        slug: 'cardmarket-id',
        type: 'string',
      },
    ],
  },
  {
    label: 'URLs',
    attributes: [
      {
        name: 'uri',
        label: 'URL',
        slug: 'url',
        type: 'string',
      },
      {
        name: 'scryfall_uri',
        label: 'Scryfall URL',
        slug: 'scryfall-url',
        type: 'string',
      },
      {
        name: 'set_search_uri',
        label: 'Set Search URL',
        slug: 'set-search-url',
        type: 'string',
      },
      {
        name: 'scryfall_set_uri',
        label: 'Scryfall Set URL',
        slug: 'scryfall-set-url',
        type: 'string',
      },
      {
        name: 'rulings_uri',
        label: 'Rulings URL',
        slug: 'rulings-url',
        type: 'string',
      },
      {
        name: 'prints_search_uri',
        label: 'Prints Search URL',
        slug: 'prints-search-url',
        type: 'string',
      },
      {
        name: 'related_uris',
        label: 'Related URLs',
        slug: 'related-urls',
        type: 'string[]',
      },
      {
        name: 'purchase_uris',
        label: 'Purchase URLs',
        slug: 'purchase-urls',
        type: 'string[]',
      },
    ],
  },
  {
    label: 'Platform Ranks',
    attributes: [
      {
        name: 'edhrec_rank',
        label: 'EDHREC Rank',
        shortLabel: 'EDHREC',
        slug: 'edhrec-rank',
        type: 'number',
        sortable: true,
      },
      {
        name: 'penny_rank',
        label: 'Penny Rank',
        shortLabel: 'Penny',
        slug: 'penny-rank',
        type: 'number',
        sortable: true,
      },
    ],
  },
  {
    label: 'Other',
    attributes: [
      {
        name: 'flavor_name',
        label: 'Flavor Name',
        slug: 'flavor-name',
        type: 'string',
      },
      {
        name: 'released_at',
        label: 'Release Date',
        slug: 'release-date',
        type: 'string',
        sortable: true,
      },
      {
        name: 'all_parts',
        label: 'All Parts',
        slug: 'all-parts',
      },
      {
        name: 'card_back_id',
        label: 'Card Back ID',
        slug: 'card-back-id',
      },
      {
        name: 'card_faces',
        label: 'Card Faces',
        slug: 'card-faces',
      },
      {
        name: 'story_spotlight',
        label: 'Story Spotlight',
        slug: 'story-spotlight',
      },
      {
        name: 'promo_types',
        label: 'Promo Types',
        slug: 'promo-types',
      },
      {
        name: 'preview',
        label: 'Preview',
        slug: 'preview',
      },
      {
        name: 'prices',
        label: 'Prices',
        slug: 'prices',
      },
      {
        name: 'lang',
        label: 'Language',
        slug: 'language',
      },
    ],
  },
]

export const cardAttributes = groupedCardAttributes.flatMap(
  (group) => group.attributes,
)

export const cardAttributeNames = cardAttributes.map(
  (attribute) => attribute.name as string,
)
