import { RowWithData } from '../data/types'

function formatErrors(missingRows: RowWithData[]) {
  if (missingRows.length === 0) {
    return null
  }

  const errors = missingRows.map((row) => {
    if (row.card == null) {
      return row.input?.name ?? `Line ${row.input?.row}`
    }
    return `${row.card.name} - ${row.card.set} ${row.card.collector_number}`
  })

  return `\n\nUnable to fetch images for ${missingRows.length} ${
    missingRows.length === 1 ? 'row' : 'rows'
  }:\n\n${errors}\n`
}

export function generateInfoFile(
  imageType: string,
  missingRows: RowWithData[],
) {
  const errors = formatErrors(missingRows)

  return `Images provided by Scryfall - http://scryfall.com
Archive generated by the "MTG List Formatter" by Lucky Paper - https://luckypaper.co/resources/list-formatter/

Generated ${new Date().toISOString().slice(0, 10)}
Image Type "${imageType}"

Questions? Issues? Feature request? https://twitter.com/ahmattox
${errors != null ? errors : ''}`
}
